import {
  Box,
  Center,
  Flex,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import MainHeaderBar from "../components/atom/main-header-bar"
import Layout from "../components/layout"
import HatenaArticles from "../components/organisms/hatena-articles"
import TagsList from "../components/organisms/tags-list"
import Seo from "../components/seo"

const About = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const siteTitle = data.site.siteMetadata?.title || `Title`

  const sideBarContents = [<HatenaArticles />, <TagsList />]
  return (
    <Layout
      location={location}
      title={siteTitle}
      sideBarContents={sideBarContents}
    >
      <Seo title="このブログについて"></Seo>
      <MainHeaderBar as="h1">このブログと運営者について</MainHeaderBar>
      <Box className="blog-post">
        <Heading as="h2">このブログに書くこと</Heading>
        <Text>
          このブログは、事業会社の情報システム部門で働く筆者が、企画と開発の力をつけることを目指しながら、仕事の学びを伝えるブログです。
          <UnorderedList>
            <ListItem>技術的な勉強メモ</ListItem>
            <ListItem>システムの企画開発業務で学んだこと</ListItem>
            <ListItem>その他、人生論とか息抜きとかまったく関係ないこと</ListItem>
          </UnorderedList>
          について書きます。
        </Text>

        <Text>意見はわたし個人のものであり、所属する組織とは何の関係もございません。</Text>

        <Heading as="h3">技術的な勉強メモ</Heading>
        <Text>
          仕事で使う技術や、独学でやってみたいと思った技術をを自分で勉強した際のメモを残します。
        </Text>
        <Text>
          <UnorderedList>
            <ListItem>
              仕事で使うので話についていくために触ってみた系
              <UnorderedList>
                <ListItem>Linuxの基礎知識</ListItem>
                <ListItem>
                  AWS（EC2、ECS（Fargate）、CodePipeline、など）
                </ListItem>
                <ListItem>Git</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              フロントが楽しいので勉強してみた系
              <UnorderedList>
                <ListItem>JavaScript</ListItem>
                <ListItem>React</ListItem>
                <ListItem>TypeScript</ListItem>
                <ListItem>socket.io</ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
        </Text>
        <Text>
          仕事でガリガリ実装する機会は少ないので、初心者の書いたメモと思ってください。
        </Text>
        <Text>間違っていたらご連絡いただけたら喜びます。</Text>
        <Heading as="h3">システムの企画開発業務で学んだこと</Heading>
        <Text>
          システム開発業務の中で、
          <UnorderedList>
            <ListItem>働く中で学んだこと、教わったこと</ListItem>
            <ListItem>働く中で考えが変わったこと</ListItem>
            <ListItem>働く中で知って、さらに勉強したこと</ListItem>
          </UnorderedList>
          など、ビジネスパーソンとして勉強になったことを残しておきたいと思います。
        </Text>
        <Heading as="h3">その他、息抜き</Heading>
        <Text>
          このブログは雑記ブログ（日常ブログ）のため、息抜きになるような趣味や生活のことも書きます。
        </Text>
        {/* <Heading as="h2">読んでほしい人</Heading> */}
        {/* <Text>「システムの企画開発業務で成長したこと」は5年前の自分に、「プログラミングの勉強メモ」は数か月先の自分に向けて書きます。</Text> */}
        {/* <Text>公開することで、過去の自分と同じような理系大学生の方が社会人生活やシステムの仕事をイメージできるといいなと思いブログにしました。</Text> */}
        {/* <Text>「プログラミングの勉強メモ」については、他の方のメモに助けられることが多いので私も役に立てたらと思いブログにしました。</Text> */}
        <Heading as="h2" id="profile">
          運営者について
        </Heading>
        <Flex
          justify="left"
          align="center"
          mt={4}
          mb={4}
          display={{ base: "block", sm: "flex" }}
        >
          <Box mb={4}>
            <StaticImage
              className="bio-avatar"
              layout="constrained"
              formats={["auto", "webp", "avif"]}
              src="../images/profile-pic.png"
              width={60}
              height={60}
              quality={95}
              alt="プロフィールイメージ"
            />
          </Box>
          <Box my={3}>
            <Text fontSize="lg" fontWeight="bold">
              はち子
            </Text>
          </Box>
        </Flex>

        <Text>199X年生まれの女性です。</Text>
        <Text>
          事業会社の情報システム部門でシステムの企画開発、運用をしています。
        </Text>
        <Text>
          システム部門としてのビジネス貢献をすべく日々修行中です。
          技術についても興味を持ったことを勉強しています。
          日々学んだことを書き留める場としてブログを立ち上げました。
        </Text>
        <Text>
          大学院卒業後、新卒では現場業務を経験し、2018年より情報システム部門にいます。
        </Text>
        <Heading as="h3" size="xs">
          保有資格
        </Heading>
        <UnorderedList>
          <ListItem>AWS 認定ソリューションアーキテクト - アソシエイト</ListItem>
          <ListItem>基本情報技術者・応用情報技術者</ListItem>
          <ListItem>Oracle認定JavaプログラマSE 7 Silver</ListItem>
          <ListItem>第三種電気主任技術者</ListItem>
          <ListItem>第一級陸上無線技術士</ListItem>
        </UnorderedList>
        <Heading as="h2">Gatsby.jsでブログを作成</Heading>
        <Text>
          もともとはてなブログで、プログラミング勉強メモを中心に記事を書いていましたが、
          フロントエンドの勉強を兼ねてGatsby.jsを使ってブログを作成します。
        </Text>
        <Heading as="h2" id="profile">
          当サイトに掲載されている広告について
        </Heading>
        <Heading as="h3">広告配信サービス</Heading>
        <Text>
          当サイトでは、当サイトでは、第三者配信の広告サービス（Googleアドセンス）を利用します。
        </Text>
        <Text>
          このような広告配信事業者（第三者配信事業者）は、ユーザーの興味に応じた商品やサービスの広告を表示するため、Cookie
          を使用して、ユーザーがそのウェブサイトや他のウェブサイトに過去にアクセスした際の情報に基づいて、広告を配信します。
        </Text>
        <Text>
          <a href="https://adssettings.google.com/authenticated">広告設定</a>
          で、このようなパーソナライズ広告を無効にできます。
        </Text>
        <Heading as="h3">Amazonアソシエイト</Heading>
        <Text>{`Amazonのアソシエイトとして、${siteTitle}は適格販売により収入を得ています。`}</Text>
        <Heading as="h3">バリューコマースアフィリエイトサービス</Heading>
        <Text>
          {`当サイト${siteTitle}はバリューコマースのアフィリエイトプログラムに参加しています。
            広告主のマーケティング活動効果を追跡するために、サイト上でCookie機能を使用しております。
            このプログラムにおいて取得・収集される情報につきましては、バリューコマースのアフィリエイトプログラムにおける
            最新のプライバシーポリシー（個人情報保護方針）をご確認ください。`}
          <a href="https://www.valuecommerce.co.jp/privacy_policy/">
            https://www.valuecommerce.co.jp/privacy_policy/
          </a>
        </Text>
      </Box>
    </Layout>
  )
}

export default About

