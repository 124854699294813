import { Box, Text, Link as ChakraLink } from "@chakra-ui/react"
import * as React from "react"
import SideHeader from "../atom/side-header"

const HatenaArticles = () => {
  return (
    <Box  maxW="800px" mx="auto">
      <SideHeader>過去の記事について</SideHeader>
      <Box>
      <Text fontSize="sm">はてなブログに掲載の記事（主にプログラミングメモ）については
      <ChakraLink
        href="https://tomiko0404.hatenablog.com/"
        _hover={{textDecoration: "underline"}}
        _focus={{ boxShadow: "none" }}
        isExternal
        color="blue.500"
      >
        こちら
      </ChakraLink>
      に掲載しております。（本ブログに移行中）</Text>
      </Box>
    </Box>
  )
}

export default HatenaArticles
