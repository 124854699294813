import { Heading } from "@chakra-ui/react"
import * as React from "react"



const MainHeaderBar = ({ children , as='h1'}) => {

    return (

        <Heading as={as}
            size="md"
            color="white"
            bg="orange.900"
            p={6}
            mb={5}
            borderRadius="5px">
            {children}
        </Heading>

    )

}

export default MainHeaderBar