import { Box, ListItem, UnorderedList } from "@chakra-ui/react"
import { useStaticQuery, graphql, Link } from "gatsby"
import _ from "lodash"
import * as React from "react"
import { AllTagDefinition } from "../../params/all-tag-difinition"
import SideHeader from "../atom/side-header"

const TagsList = () => {
  const data = useStaticQuery(graphql`
    {
      tagsGroup: allMdx {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
    }
  `)

  const tags = data.tagsGroup.group

  return (
    <Box maxW="800px" mx="auto">
      <Link to="/tags">
        <SideHeader>タグ一覧</SideHeader>
      </Link>

      {/* <TagLabelHstack tags={tags}></TagLabelHstack> */}
      <UnorderedList listStyleType="none" display="flex" flexWrap="wrap">
        {tags.map((tag) => {
          // タグの英名を取得する（gatsby-node.jsと同じ処理である必要があります）============
          // この処理は廃止。
          // const tagDefinition = AllTagDefinition.filter(
          //   (item) => item.name === tag.fieldValue
          // )
          // const tagSlug =
          //   tagDefinition.length === 0 ? tag.fieldValue : tagDefinition[0].slug // 定義に無かった場合（filterした結果が空配列）は日本語のままslugにする。
          // =====================================================================

          const tagSlug = _.kebabCase(tag.fieldValue)
          return (
            <Link to={`/tags/${tagSlug}`}>
              <ListItem
                p={1}
                m={1}
                bgColor="gray.100"
                fontSize="sm"
                _hover={{ textDecoration: "underline" }}
              >{`${tag.fieldValue} (${tag.totalCount})`}</ListItem>
            </Link>
          )
        })}
      </UnorderedList>
    </Box>
  )
}

export default TagsList
